body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth; }

/* line 10, ../scss/_frame.scss */
body {
  background-color: #f5f5f5; }

/* line 14, ../scss/_frame.scss */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
 }

/* line 18, ../scss/_frame.scss */
::-webkit-scrollbar-track {
  background: #F5F5F5; }

/* line 22, ../scss/_frame.scss */
::-webkit-scrollbar-thumb {
  background: #3B61DD;
  border-radius: 2px; }

/* line 27, ../scss/_frame.scss */
::-webkit-scrollbar-thumb:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%); }

  .container {
    padding: 14px 0 0 0!important;
}
.site-header.scrolled .site-logo {
  position: relative;
  z-index: 100;
  bottom: 19px;
}
.site-header.scrolled .site-menu-toggle {
  top: -7px;
  position: relative;
}

div#root {
  overflow: hidden;
}
.site-header [data-aos^="fade"][data-aos^="fade"].aos-animate {
    opacity: 1;
    transform: translate(0);
    position: relative; 
    bottom: 30px;
}
.heading, .testimonial-section .heading, .slider-section .heading, .blog-post-entry .heading {
  font-size: 50px;
  font-family: "Playfair Display",times,serif;
  font-weight: 700;
}
.bg-light p.mb-4 {
  text-align: justify;
  font-weight: 400;
}
 h2 {
  margin-top: 14px;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1)!important;
  -webkit-background-clip: text!important;
  background-clip: text!important;
  color: transparent!important;
}
h2.text-white {
   margin-top: 14px;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1)!important;
  -webkit-background-clip: text!important;
  background-clip: text!important;
  color: transparent!important;
}
.text-primary {
  background: linear-gradient(to right, #00688B, #50C878, #4169E1)!important;
  -webkit-background-clip: text!important;
  background-clip: text!important;
  color: transparent!important;
}
.mb-5 .text-white{
  background: linear-gradient(to right, #00688B, #50C878, #4169E1)!important;
  -webkit-background-clip: text!important;
  background-clip: text!important;
  color: transparent!important;
}
.mb-5 h3{
  font-size:20px;
}
.text-left {
  text-align: left!important;
  margin-left: 17px;
  margin-top: -54px;
}
.food-menu {
  margin-bottom: 3rem!important;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04);
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 13px 10px 10px 34px;
}

.food-menu-tabs .nav-tabs {
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  border-bottom: none;
  margin: 0 0px;
  /* box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04); */
  z-index: 2;
  top: 32px;
  /* background: linear-gradient(to right, #00688B, #50C878, #4169E1); */
  position: relative;
  /* border-radius: 10px; */
  padding: 0px 0px 0px 0px;
}
.food-menu-tabs .nav-tabs li a.active {
  border: none;
  background: none;
  color: #fff;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1);

  border-radius: 2em;
}
.btn-primary {
  cursor: pointer;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1);
  color: #fff!important;
  border:none!important;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1);
  color: #fff!important;
}
.bg-light .row.align-items-center {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04);
  border: solid 10px #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFFFF;
  margin: 53px 0 0 12px;
}
.tab-content>.active {
  display: block;
  margin-top: -12px;
}
.img-absolute {
  position: absolute;
  bottom: -100px;
  right: -70px;
}
p {
  line-height: 29px;
}
.post .media-custom a{
  background: linear-gradient(to right, #00688B, #50C878, #4169E1)!important;
  -webkit-background-clip: text!important;
  background-clip: text!important;
  color: transparent!important;
}
.post .media-custom p{
text-align: justify;
}
.contact-section{
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04);
  border: solid 10px #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  background-color: #FFFFFF;
  margin: 0px 25px 0 69px;
}
.room {
  position: relative;
  display: block;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04);
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 11px 11px;
  margin-bottom: 35px;
}
.room img.img-fluid.mb-3 {
  border-radius: 24px;
}
.room h2 {
  font-size: 24px;
}
.contact-heading {
  margin-top: 21px;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight:700;
}
.site-logo img {
  width: 88px;
  position: relative;
  top: 10px;
}
.post .media-custom {
  border-radius: 31px;
  background-color:#fff;
  /* background: linear-gradient(to right, #00688B, #50C878, #4169E1); */
}
.custom-contact-row {
  padding-right: 18px;
}
.contact-section .contact-info p .d-block {
  font-size: 17px;
}
 .site-header.scrolled {
  padding: 14px 0 0 0;
  background: #fff;
  -webkit-box-shadow: 0 5px 20px -5px rgba(0,0,0,.1);
  box-shadow: 0 5px 20px -5px rgba(0,0,0,.1);
}
  .section {
    padding: 0em 0;
}
.site-header {
  position: fixed;
  background: #ffffff2b;
  top: 0;
  width: 100%;
  /* padding: 60px 0; */
  z-index: 2;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}

.menu-open .site-navbar {
  border: 10px solid #f8f9fa;
  background: white;
  display: block;
}
.check-availabilty {
  position: relative;
  left: 155px;
  top: 286px;
  margin-bottom: 27px;
}
.check-availabilty .block-32 {
  background-color: #fff;
  width: 70%;
}
.check-availabilty label.text-white  {
  color:black!important;
  margin-top:8px;
  margin-left:5px;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1)!important;
  -webkit-background-clip: text!important;
  background-clip: text!important;
  color: transparent!important;
}
.footer-section .contact-info span.d-block {
  font-style: italic;
  color: #fff;
  /* float: left; */
  margin-left: -35px;
}
.col-md-3.mb-5.social img {
  width: 30px;
  margin-bottom: 10px;
}
.text-black {
  color: #000!important;
  font-size: 16px;
}
/* iframe {
  border: 0;
  border-radius: 23px;
} */
.media-custom .meta-post {
  color: #adb5bd;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-size: 9px;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    position: relative;
    bottom: 92px;
    background: transparent;
}
iframe {
  border: 0;
  border-radius: 23px;
  height: 353px;
}
.bg-light.position-relative {
  position: relative!important;
  right: 44px;
  top: -20px;
}
.block-32 {
  background: #fff;
  padding: 0 12px 19px 14px;
}

.dashboardContaint {
  /* position: relative; */
  padding: 30px;
  /* z-index: -1; */
}

.mainBoxhome {
  position: relative;
  overflow: hidden;
  /* height: 100vh; */
}

.slidImg{
  width: 100%;
  height:80vh!important;
}
.mainBoxhomecon {
  position: absolute;
  top: 0;
  width: 100%;
}

.field-icon-wrap.Adults {
  position: relative;
  top: 7px;
}
.field-icon-wrap.children {
  position: relative;
  top: 6px;
}
.col-md-6.col-lg-3.align-self-end {
  position: relative;
  bottom: 13px;
}
.testimonial-section{
  overflow: hidden;
}
.dashboardCard {
  background: #ba792b;
  padding: 20px;
  margin: 30px;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
}
.dashboardCard a {
  color: #ffffff;
}
.dashboardCard a h5{
  color: #ffffff;
}
.GallaryImg img {
  width: 100%;
}
.GallaryImg {
  width: 60px;
}

.btmic{
  width: 56px;
  margin-bottom: 10px;
}

.roomAdd {
  padding: 5px 15px;
  background: #0000ff;
  color: #ffffff !important;
  display: block;
  margin: auto;
  width: 80px;
}
.roomadd input {
  width: 100%;
  margin: 6px;
}
.roomadd {
  padding: 20px;
}
.roomaddBack {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
}
.roomaddBackB {
  cursor: pointer;
}

.roomDImg {
  width: 100%;
}
.roomDImg img {
  width: 100%;
}
.login_button.newQue {
  float: right;
  font-size: 37px;
  margin-top: -20px;
}
.roomT .card-title {
  float: left;
}

.categorySelect select {
  width: 100%;
  margin-top: 5px;
  height: 37px;
}


.resModel {
  max-width: 400px !important;
  margin: 5% auto 15%;
}
.login_page {
  text-align: center;
  font-size: 35px;
  padding-top: 50px;
}
.disBtn {
  opacity: .6;
  pointer-events: none;
}
.enSub {
  margin: 15px auto;
}
.enSubBtn {
  text-align: center;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #03030396;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.emailSize{
  font-size: 20px;
}

.testc{
  /* color: #0d6efd !important; */
  background: linear-gradient(to right, #00688B, #50C878, #4169E1)!important;
  -webkit-background-clip: text!important;
  background-clip: text!important;
  color: transparent!important;
}


.dataTables_filter.category {
  float: right;
}


.btn-primary:focus {
  color: #fff;
  background-color: #ffba5a;
  border-color: #ffba5a;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.categoryImage {
  display: -webkit-box!important;
  overflow: auto;
}
.knsl-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px;
}
.knsl-mb-60 {
  margin-top: 60px;
}

.knsl-filter .knsl-work-category {
  -webkit-box-shadow: 0 2px 48px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 4%);
  position: relative;
  font-size: 15px;
  display: block;
  background-color: #FFFFFF;
  color: #383A4E;
  height: 50px;
  border-radius: 50px;
  margin: 0 5px;
  line-height: 50px;
  padding: 0 40px;
  margin-bottom: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.knsl-filter .knsl-work-category.knsl-current {
  color: #FFFFFF;
  background: linear-gradient(to right, #00688B, #50C878, #4169E1);
    color: #fff!important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 26px;
}
.contact-section img {
  width: 24px;
  position: relative;
  bottom: 2px;
  margin-right: 6px;
}
a {
  cursor: pointer!important;
}

.cmGallery1 {
  border-radius: 16px!important;
  height: 259px!important;
  overflow: hidden;
}

.eventImg{
  height: 240px;
  overflow: hidden;
}
.evtBtn{
  width: 100px;
  margin: 30px;
}

.loginBox{
  max-width: 300px;
  margin: 100px auto;
  text-align: center;
}


.footer-section {
  background: #1a1a1a;
  color: #fff;
  padding: 2em 0 1em !important;
}
.pt-5, .py-5 {
  padding-top: 0rem!important;
  font-size:12px
}
.mb-4 {
  margin-bottom: 0.5rem!important;
font-size:15px;
}
a {
  color: #3579af;
  /* text-decoration: underline; */
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 1;
  transition-property: opacity , transform;
}


.home_single_slider {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}
.knsl-mb-60 {
  margin-top: 102px;
}

.editfield{
  padding: 0 10px;
}
td.actionicon a {
  position: relative;
  left: 144px;
  /* padding: 22px; */
  margin: 0 5px;
}
td.actionicon1 a {
  position: relative;
  left: 48px;
  /* padding: 22px; */
  margin: 0 5px;
}


td.actionicon2  {
  position: relative;
  left: 160px;
  /* padding: 22px; */
  margin: 0 5px;
}
p.text-white.aos-init.aos-animate {
  color: #ffff!important;
}
.contactno {
  max-width: 300px;
  display: inline-block;
  margin-top: -23px;
  /* background: #ffffff91; */
  padding: 5px;

}
.contactno p {
 color: #000000;
 font-weight: 700;
 margin-bottom: 1px;
 
}
@media screen and (min-width: 980px) {

.slidImg {
    width: 100%;
    height: 50vh;
}
}
@media screen and (max-width: 540px){

  .check-availabilty {
    position: relative;
    left: 91px;
    top: 286px;
    margin-bottom: 236px;
}
.site-menu-toggle {
  top: 0;
  float: right;
  width: 40px;
  height: 45px;
  position: relative;
  margin: 0 auto;
  z-index: 200;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  margin-right: 1px;
}
.site-logo {
  padding-left: 1px;
}
.contactno {
  max-width: 161px;
  display: inline-block;
  margin-top: -47px;
  /* background: #ffffff91; */
  padding: 5px;
}
  .container {
    padding: 14px 26px!important;
}
  [data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s;
    margin-bottom: 33px;
  }
  .contact-section {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.04);
    border: solid 10px #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
    background-color: #FFFFFF;
    margin: 0px 25px 0 30px;
}
  }
  @media screen and (max-width: 414px){
    .check-availabilty {
      position: relative;
      left: 27px;
      width: 136%;
      /* top: 286px; */
      margin-bottom: 236px;
  }
  


.slidImg{
  width: 100%;
  height:35vh!important;
}
.img-absolute {
  position: absolute;
  bottom: -103px;
  right: -43px;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 239px!important;
  outline: none;
  padding: 20px 0;
}
.telinputno .react-tel-input .form-control {
  width: 270px;
  padding: 22px 0;
}
  }


